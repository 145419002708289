<template>
    <div class="box">
        
        <div class="header">
            <div class="title">
                <div>我的数据</div>
                <el-button v-if="!touristFlag" plain size="small"  @click="dataClick"  icon="el-icon-plus" round>上传</el-button>
            </div>
            <div class='header-right'>
                <div class="more">
                    <el-input
                        placeholder="请输入数据名称"
                        v-model="input"
                        @clear='dataClearClick'
                        clearable>
                        <el-button @click="searchMydata" class="btn" slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </div>
                <div class="block">
                    <el-pagination
                        background
                        @current-change="handleChangePage"
                        :current-page.sync="pagination.currentPage"
                        :page-size="pagination.limit"
                        layout="prev, pager, next"
                        :total="pagination.total">
                    </el-pagination>
                </div>
            </div>
           
        </div>
        <div v-if="!touristFlag" class="content">
            <!-- <el-table
                :data="tableData"
                :header-row-style="{background:'#F6F7FA'}"
                :header-cell-style="{background:'#F6F7FA',color:'#484848',fontWeight:'700',fontSize:'15px',borderBottom:'none'}"
                style="width: 100%">
                    <el-table-column
                        prop="eggrollName"
                        label="数据名称"
                        >
                    </el-table-column>
                    <el-table-column
                        prop="createTime"
                        label="采集日期"
                        >
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="文件名称"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="size"
                        label="文件大小">
                    </el-table-column>
                    <el-table-column
                        prop="contentType"
                        label="文件类型">
                    </el-table-column>
                    <el-table-column
                        prop="rowsNum"
                        label="数据行数">
                    </el-table-column>
                    <el-table-column
                        label="操作">
                        <template slot-scope="scope">
                            <el-button @click="gotoDataDetail(scope.$index, scope.row)"  type="text" size="small">查看详情</el-button>
                        </template>
                    </el-table-column>
            </el-table> -->
            <el-row v-if="tableData.length>0" :gutter="30">
                <el-col class='col-box' v-for="(item,index) in tableData"
                        :key="index" :lg="6" :xl= '6'>
                    <div class="radiu-box"
                        @click="gotoDataDetail(item)"
                        >
                        
                        <div class="content-box">
                            <el-tooltip class="item" effect="dark" :content="item.name" placement="top">
                               <div class="content-title">
                                    {{item.name}}
                                </div>
                            </el-tooltip>
                            
                            <div class="content-footer">
                                <el-tag>{{item.rowsNum}}数据</el-tag>
                                <!-- <el-tag>{{item.contentType}}</el-tag> -->
                            </div>
                        </div>
                    </div>
                    
                </el-col>
            </el-row>
            <div v-else class="img-box"  >
                <img src="@/assets/nodataset.png" alt="">
                <div>
                    暂无数据
                </div>
            </div>
        </div>
        <div v-if="touristFlag"  class="img-box">
            <img src="@/assets/binding.png" class="img">
            <div>
                绑定联邦方后激活
            </div>
        </div>  
        <upload @uploadSuccess='uploadSuccess' @cancleCreateClick='cancleCreateClick' :uploadVisible='uploadVisible'></upload>
        <upload-step :successObj='successObj' @cancleCreateClickStep='cancleCreateClickStep' :uploadStepVisible='uploadStepVisible'></upload-step>
    </div>
</template>
<script>
import BreadCrumb from '@/components/breadcrumb.vue'
import upload from '@/components/upload'
import uploadStep from '@/components/uploadDialog'
import { listCurrent,healthz} from '@/api/index'
import { getAuth , getTenantId , setIndex } from "@/utils/auth";
export default {
    name: 'Mydata',
    components:{
        BreadCrumb,
        upload,
        uploadStep
    },
    data() {
        return {
            uploadVisible: false,
            uploadStepVisible: false,
            successObj: null,
            breadArray: [
                {
                    name: '我的数据',

                }
        
            ],
            pagination: {
                offset: 0,
                total: 0,
                currentPage: 1,
                limit: 16   // 16
            },
            input: '',
            tableData:[
            ],
            touristFlag: false,
            healthzStatus: 0
        }
    },
    created() {
        if(sessionStorage.getItem('tourist')== 'true') {
             this.touristFlag = true
        } else {
             this.touristFlag = false
             this.listCurrent()
             this.healthz()
        }
       
    },
    methods: {
         // 心跳检查
        async healthz() {
            let Url =  sessionStorage.getItem("uploadUrl")         
            await healthz(Url).then(res=>{
                if(res.data.code == 200 ) {
                    // 心跳检测成功
                    // return true
                    this.healthzStatus = 1 // 表示心跳检测成功
                } else {
                    this.healthzStatus = 2 // 表示心跳检测接口成功，但是没有安装证书
                }
            })
            .catch(res=>{
                this.healthzStatus = 2 // 表示心跳检测失败
            })
        },
        // 数据查询
        searchMydata() {
            this.pagination.currentPage = 1
            this.listCurrent()
        },
        // 数据清空
        dataClearClick() {
            this.input = ''
            this.listCurrent()
        },
        dataClick() {
            if(this.healthzStatus == 1) {
                // 表示心跳检测成功
                this.uploadVisible =  true
            } else if(this.healthzStatus == 2){
                // // 表示心跳检测接口成功，但是没有安装证书
                this.$message({
                    dangerouslyUseHTMLString: true,
                    type: 'error',
                    duration: 3 * 1000,
                    message: `网络安全证书缺失，<a class="ssl" style="color:#145358;"  type="primary">请下载对应证书</a>；或者联系运维人员检查边缘端网络配置`
                    
                })
                const ssl = document.getElementsByClassName("ssl");
                // for (var i = 0; i < ssl.length; i++) {
                //     ssl[i].addEventListener("click", () => {
                //         this.$message.closeAll()
                //         this.$DialogSSL()
                //     });
                // }
            }
            
            // this.uploadStepVisible =true
        },
        dataClearClick() {
            this.input = ''
            this.listCurrent()
        },
        cancleCreateClick(){
            this.uploadVisible = false;
        },
        cancleCreateClickStep() {
            this.uploadStepVisible = false
        },
        uploadSuccess(obj) {
            this.uploadVisible = false
            this.uploadStepVisible = true
            this.successObj = obj
        },
        search() {
            this.pagination.currentPage = 1
            this.listCurrent()
            // this.input = ''
        },
        handleChangePage(current) {
            this.pagination.currentPage = current
            this.listCurrent()
        },
        // 我的数据
        async listCurrent() {
            let params = {
                name: this.input,
                pageNo:this.pagination.currentPage,
                pageSize: this.pagination.limit

            }
            let id = getTenantId()
            await listCurrent(params,id).then(res=>{
                this.tableData = res.data.result.records
                this.pagination.total = res.data.result.total
            }) 
            .catch(res=>{
                
            })
        },
        gotoDataDetail(row) {
           
            this.$router.push({
                name: 'DataDetail',
                query: { 
                    partyId: row.partyId,
                    id: row.id
                }
            })
        }
    },
    beforeRouteLeave (to, from, next) {
        // console.log(to)
        // 如果下一个页面不是 我的任务详情页面，就不需要缓存
        if(to.name !== 'DataDetail') {
            this.$store.commit('noKeepAlive', from.name)
        }
        next()
    }
}
</script>
<style lang="scss" scoped>
.box{
    height: 100%;
    background: #F4F6F8;
    .header{
        height: 40px;
        // line-height: 50px;
        margin: 20px 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title{
            cursor: pointer;
            display: flex;
             align-items: center;
            float: left;
            font-size: 24px;
            font-weight: 700;
            color: #484848;
            div{
                margin-right: 20px;
            }
            
        }
        .header-right{
            display: flex;
            align-items: center;
            .block{
                
                ::v-deep.el-pagination .el-icon-arrow-left{
                    font-size: 20px !important;
                    // color: #000;
                    font-weight: 700 !important;
                }
                ::v-deep.el-pagination .el-icon-arrow-right{
                    font-size: 20px !important;
                    // color: #000;
                    font-weight: 700 !important;
                }
                ::v-deep .el-pagination{
                    overflow: hidden;
                    // float: right;
                    display: flex;
                    align-items: center;
                    height: 50px;
                }
                ::v-deep .btn-prev{
                    background-color: #fff;
                }
                ::v-deep .btn-next{
                    background-color: #fff;
                }
                ::v-deep .el-pager{
                    // display: none;
                }
                
                    
            }
        }
        
    }
    .content{
        // background-color: #fff;
        padding: 30px;
        .radiu-box{ 
            position: relative; 
            width: 244px;
            height: 184px;
            cursor: pointer;
            background: linear-gradient(to right, #ededf6,#fff);
            margin-bottom: 40px;
            .content-box{ 
                position: absolute;
                top: 20px;
                width: 100%;
                height: 100%;
                text-align: center;
                .content-title{
                    margin-top: 20px;
                    // height: 40px;
                    font-size: 20px;
                    font-weight: 700;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    word-break:break-all;
                    overflow: hidden;
                }
                .content-footer{
                    // width: 200px;
                    // text-align: center;
                    // position: absolute;
                    // /* margin: 0 auto; */
                    // bottom: 30px;
                    // left: 50%;
                    // margin-left: -100px;
                      margin-top: 30px;
                    ::v-deep .el-tag {
                        margin-right: 10px;
                    }
                }
            }
        }
        
        .radiu-box:before{
            position: absolute; 
            top:-10px; 
            content: "";
            width: 244px; 
            height: 20px; 
            border-radius:50%; 
            background: #fff;
            box-shadow: 0px 3px 15px 9px rgba(226,226,226,0.29);
        }

        .radiu-box:after{
            position: absolute; 
            bottom:-10px; 
            content: ""; 
            width: 244px; 
            height: 20px; 
            border-radius:50%; 
            background: linear-gradient(to right, #ededf6,#fff);
        }
        ::v-deep .el-table th > .cell {
            text-align: center;
        }
    
        ::v-deep .el-table .cell {
            text-align: center;
        }
        .block{
            margin-bottom: 30px;
            margin-top: 30px;
            overflow: hidden;
            ::v-deep .el-pagination{
                float: right;
            }
        }
    }
    .img-box{
        // height: 236px;
        padding: 12px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .img{
            height: 100px;
        }
        div{
            color: #9a9a9a;
            font-size: 20px;
            font-weight: 700;
            margin-top: 14px;
        }
    }
}
</style>

<template>
    <div class="content">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
    .content {
        padding: 20px;
        background-color: #f9f9f9;
    }
</style>
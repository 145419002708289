<template>
  <div>
        <div  class="img-box">
            <img style="width:80%;margin:10px 0;"   v-if="classObj.openSidebar"   src="@/assets/logo.png" alt="">
            <img style="width:80%;margin:10px 0;"   v-if="classObj.hideSidebar"    src="@/assets/logo-small.png" alt="">
      
        </div>
        <el-menu
            :default-active="activeMenu"
            :collapse="isCollapse"
            :unique-opened="false"
            :collapse-transition="false"
            mode="vertical"
        >
            <sidebar-item v-for="route in routes" :key="route.path" :item="route" :base-path="route.path" />
        </el-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'

export default {
  components: { SidebarItem, Logo },
  computed: {
    ...mapGetters([
      'sidebar'
    ]),
    routes() {
      return this.$router.options.routes
    },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    classObj() {
        return {
            hideSidebar: !this.sidebar.opened,
            openSidebar: this.sidebar.opened,
        }
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  }
}
</script>

<style lang="scss" scoped>
.img-box{
  text-align: center
}
::v-deep .el-menu {
    height: 100%;
     border-right: none;
}
.openSidebar{

}
.hideSidebar{

}
</style>

<template>
    <el-dialog
        title="构成条件设置"
        v-if="chooseVisible"
        :visible.sync="chooseVisible"
        width="40%"
        center
        :before-close="handleClose">
        <el-form  label-position="top"  class="form">
            <el-form-item label="特征值筛选条件">
               <el-checkbox-group v-model="checkList">
                    <el-checkbox 
                        v-for="item in features" 
                        :key="item" 
                        :label="item"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="数据列筛选条件">
                <div class="desc">
                   注：左侧为数据可选择列，右侧为已选择列。请先从左侧选择需要数据，点击中间箭头将选中数据移动到右侧已选择部分；同时点击确定后，将以右侧列作为特征列进行数据筛选
                </div>
                <el-transfer 
                    :titles='title'
                    v-model="value" 
                    :data="colums">
                </el-transfer>
            </el-form-item>
        </el-form>
       
        <span slot="footer" class="dialog-footer">
            <el-button :loading='loading' @click="confirm" type="primary">确 定</el-button>
            <el-button @click="handleClose">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
    import { selectedColumnAndFeature, updateColumnAndFeature } from '@/api/index'
    import { getTenantId } from "@/utils/auth"
    export default {
        props: {
            visible: Boolean,
            colums: Array,
            features: Array,
            jobId: String,
            tableName: String
        },
        data() {
            return {
                value: [],
                checkList: [],
                loading: false,
                title: ['可选数据列', '已选数据列']
            }
        },
        computed: {
            chooseVisible: {
                get() {
                    return this.visible
                }
            },
            
        },
        watch: {
            features: {
                handler(newValue, oldValue) {
                    this.checkList = newValue
                },
                immediate: true
            }
        },
        methods: {
            // 记录选择的特征值和数据列
            async updateColumnAndFeature(tableName) {
                let obj = {
                    colume: this.value,
                    features: this.checkList
                }
                let data = {
                    id: this.jobId,
                    columeAndFeature: JSON.stringify(obj)
                }
                await updateColumnAndFeature(data,getTenantId()).then(res=>{
                    if(res.data.code == 200) {
                        this.value = []
                        this.checkList = []
                        this.$emit('dataSetSuccess',tableName)
                    }
                })
                .catch(err=>{

                })
            },
            // 选择数据集列和特征值
            async selectedColumnAndFeature() {
                let Url =  sessionStorage.getItem("uploadUrl")
                let data = {
                    jobId: this.jobId,
                    tableName: this.tableName,
                    colums: this.value,
                    features: this.checkList,
                    createParty: sessionStorage.getItem("nameEn")
                }
                await selectedColumnAndFeature(data,Url,getTenantId()).then(res=>{
                    if(res.data.code == 200) {
                        this.loading = false
                        let tableName = res.data.result.tableName
                        this.$message.success(res.data.message)
                        this.updateColumnAndFeature(tableName)
                        
                    } else {
                        this.$message.error(res.data.message)
                    }
                })
                .catch(err=>{
                    this.loading = false
                })
            },
            confirm() {
                if(this.value.length==0 && this.checkList.length==0) {
                    this.$message.warning('请选择特征值和数据列')
                } else if(this.checkList.length==0) {
                    this.$message.warning('请选择特征值')
                } else if(this.value.length==0) {
                    this.$message.warning('请选择数据列')
                } 
                else {
                    this.loading = true
                    this.selectedColumnAndFeature()
                }
                
            },
            handleClose() {
                this.$emit('closeDialog')
                this.value = []
                this.checkList = []
                
            }
        }
    }
</script>
<style lang='scss' scoped>
::v-deep .el-dialog__body{
    display: flex;
    justify-content: center;
}
.desc{
    color: red;
    line-height: 200%;
    margin-bottom: 20px;
}
</style>
<template>
    <div class="container">
        <div class='left'>
            <div :class="showSf?'top':'htop'">
                <div class="top-title">选择数据：</div>
                <el-row :gutter="10">
                    <el-col :span="12"
                        
                        v-for="(item,index) in fileMeta"
                        :key="index">
                        <div class="content-item">
                            <img @click='chooseDataClick(item,index)' class="dataImg" :src='item.selected?img2:img1' alt="">
                            <!-- <div @click='chooseDataClick(item,index)' class="item"  :class='item.selected?"active-data-box":"data-box"' ></div> -->
                            <div class="dataName">
                                <el-tooltip  effect="dark" :content="item.name" placement="top-start">
                                    <span>
                                        {{item.name}}
                                    </span>
                                 </el-tooltip>
                            </div>
                        </div>

                    </el-col>
                </el-row>
            </div>
            <el-divider v-if="showSf"></el-divider>
            <div v-if="showSf" class="bottom">
                <div class="bottom-title">选择算法：</div>
                <el-row :gutter="10">
                    <el-col :span="12"
                        v-for="(item,index) in algorithm"
                        :key="index">
                        <div class="content-sf-item">
                            <img @click='chooseSf(item,index)' class="sfImg" :src='item.selected?img3:img4' alt="">
                            <!-- <div @click='chooseSf(item,index)' class="item" :class='item.selected?"active-suanfa":"suanfa-box"' ></div> -->
                            <div class="dataName">
                                <el-tooltip  effect="dark" :content="item.nameCn" placement="top-start">
                                   
                                    <span>
                                        {{item.nameCn}}
                                    </span>
                                 </el-tooltip>
                               
                                
                            </div>
                        </div>  
                        
                    </el-col>
                </el-row>
            </div>
        </div>
        <el-dialog
            title="数据对比错误"
            :visible.sync="createVisible"
            width="1200"
            :before-close='beforeCloseClick'
            :show-close='false'
           
            >
            <div  class="content-box">
                <div class="title">请与发起方格式进行对比，确保该数据集的数据类型、格式符合预期：</div>
                <div class="table-box">
                    <table  class="myTable">
                        <tbody>
                            <tr>
                                <td  class="theader">数据名称</td>
                                <td v-for="(item,index) in tableData1" :key="index" class="column">{{ item }}</td>
                            </tr>
                            <tr>
                                <td  class="theader">数据格式</td>
                                <td v-for="(item,index) in tableData2" :key="index" class="column">{{ item }}</td>
                            </tr>
                        </tbody>
                    
                    </table>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button class="save" @click='beforeCloseClick' type="primary" >确定</el-button>
                <el-button plain  @click="beforeCloseClick">取消</el-button>
            </div>
        </el-dialog>
        <el-dialog
            :visible.sync="createDataVisible"
            v-if="createDataVisible"
            width="60%"
            :before-close='beforeCloseDataClick'
            :show-close='true'
            
            >
            <span slot="title" class="dialog-title">
                <span class="dialogTitle">数据选择</span>
                <!-- <span v-show="!showSf" class="">
                    <el-link type="primary" :underline="false">发起方要求格式</el-link>
                </span> -->
            </span>
            <div v-if="!showPicTableFlg">
                <el-table
                    :data="tableData"
                    border
                    ref='multipleTable'
                    :row-key="getKeys"
                    @selection-change='userSelectionChange'
                    style="width: 100%"
                    >
                        <el-table-column
                            fixed
                            type="selection"
                            :reserve-selection='true'
                            width="55">
                        </el-table-column>
                        <el-table-column
                            v-for="(item,index) in labelData"
                            :key=index
                            :prop="item.label"
                            :label="item.label"
                            width="100">
                        </el-table-column>
                </el-table>
            </div>
            <div class="pic-table" v-if="showPicTableFlg">
                <el-form  label-position="left"  class="form">
                    <el-form-item label-width="100px" v-if="evalType=='mulabel'" label="标签列筛选条件">
                        <el-checkbox-group 
                            @change="handleCheckedMulalelChange"
                            v-model="checkMulabel">
                            <el-checkbox 
                                v-for="item in mulableList" 
                                :key="item" 
                                :label="item"></el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item  v-if="evalType=='multi'" label="分类筛选条件">
                        <el-checkbox-group 
                            @change="handleCheckedMultiChange"
                            v-model="checkMulti"
                            >
                            <el-checkbox 
                                v-for="(item,key) in multiList" 
                                :key="key" 
                                :label="Object.keys(item)[0]"></el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </el-form>
                <el-table
                    :data="picListData"
                    ref='multipleTable'
                    :row-key="getKeys"
                    @selection-change='userSelectionChange'
                    style="width: 100%"
                    >   
                    <div>
                        <el-table-column
                            fixed
                            type="selection"
                            :reserve-selection='true'
                            width="55">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="名称"
                            width="100"
                            >
                        </el-table-column>
                       
                        <el-table-column width="200">
                            <template slot-scope="scope">
                               
                                <span style="margin-left: 10px">
                                    <el-image 
                                        style="width: 100px; height: 100px"
                                        :src="`${imgUrl}`+ scope.row.path_small+'&token='+ `${token}` "
                                        @click.capture="bigImg(scope.row.path)"
                                       
                                        :preview-src-list="srcList">
                                    </el-image>
                                    <!-- <img @click="bigImg(scope.row)" class="img" :src="`${imgUrl}`+ scope.row.path_small "  alt=""> -->
                                    <!-- <div>{{scope.row.name+'.jpg'}}</div> -->
                                </span>
                            </template>
                            
                        </el-table-column>
                        <span v-if="evalType=='mulabel'">
                            <template v-for="item in mulableList">
                                <el-table-column
                                    v-if=hasMultiType(item)
                                    :prop="item"
                                    :label="item"
                                    width="200"
                                    :key="getKey()"
                                    >
                                </el-table-column>
                            </template>
                        </span>
                        <span v-if="evalType=='multi'">
                            <el-table-column
                                    prop="label"
                                    label="分类"
                                    width="200"
                                    >
                                </el-table-column>
                        </span>
                    </div>  
                </el-table>
            </div>
            <div class="block">
                <div>
                    <el-button plain v-if="!showPicTableFlg"  @click='chooseAllClick(tableData)' >全选本页</el-button>
                    <el-button plain v-if="showPicTableFlg"  @click='chooseAllClick(picListData)' >全选本页</el-button>
                </div>
                <el-pagination
                    v-if="!showPicTableFlg"
                    @size-change="handleSizeChange"
                    @current-change="handleChangePage"
                    :current-page.sync="pagination.currentPage"
                    :page-size="pagination.limit"
                    :total="pagination.total">
                </el-pagination>
                <el-pagination
                    v-if="showPicTableFlg"
                    background
                    @current-change="handleChangePagePic"
                    :current-page.sync="paginationPic.currentPage"
                    :page-size="paginationPic.limit"
                    layout="prev, pager, next"
                    :total="paginationPic.total">
                </el-pagination>
                
                <div>
                    <el-button plain  @click='chooseAllDataClick'  >全量数据集</el-button>
                    <el-button class="save" @click='chooseDataSaveClick' type="primary" >确定</el-button>
                </div>
            </div>
        </el-dialog>
        <data-set-choose @dataSetSuccess='dataSetSuccess' :jobId="id" :tableName='tableName' :features='features' :colums='colums' @closeDialog='closeDialog' :visible.sync='chooseVisible'></data-set-choose>
    </div>
</template>
<script>
import { queryDataId } from '@/api/index'
import { utils } from '@/mixins/index'
import { summaryCompare, queryFlJobDataInfo, addSelectedFeature} from '@/api/task'
import { getTenantId, getToken } from "@/utils/auth"
import { listOfMata, selectedMeta, healthz, columnAndFeatureOfMeta, selectedColumnAndFeature } from '@/api/index'
import dataSetChoose from '@/components/dataSetChoose.vue'
import img1 from '@/assets/tool_bg_sensor_dataset.svg'
import img2 from '@/assets/tool_bg_image_active_dataset.svg'   
import img3 from '@/assets/tool_btn_active_model.svg'
import img4 from '@/assets/tool_bg_model.svg'
export default {
    props: {
        fileMeta: Array,
        algorithm: Array,
        id: String,
        partInfo: String,
        algFlag: Boolean,
        columnAndFeature: Object,
        partyId: Number
    },
    components: {
        dataSetChoose
    },
    mixins: [utils],
    data() {
        return {
            activesf: false,
            activedata: false,
            currentSfIndex: -1,
            currentDataIndex: -1,
            createVisible: false,
            createDataVisible: false,
            tableData1: [],
            tableData2: [],
            dataId: '',
            suanfaCom: '',
            dataName: '',
            showSf: true,
            titleText: '',
            createParty: '',
            path: '',
            params: { 

            },
            contentType: '',
            pagination: {
                offset: 0,
                total: 0,
                currentPage: 1,
                limit: 10
            },
            paginationPic: {
                offset: 0,
                total: 0,
                currentPage: 1,
                limit: 3
            },
            tableData: [{
            }],
            labelData:[],
            newData:[],
            chooseItemId: '',
            tableName: '',
            dataContentType: '',
            showPicTableFlg: false,
            listchoose: [],
            imgUrl: '',
            picListData: [],
            srcList:[],
            fqId: '',
            targetself: '',
            originContentType: '',
            img1: img1,
            img2: img2,
            img3: img3,
            img4: img4,
            selectAll: true,
            chooseVisible: false,
            colums: [],
            features: [],
            loadingFull: null,
            evalType: '',
            mulableList: [],
            multiList:[],
            checkMulti: [],
            checkMulabel: [],
            filterArray: []
        }
    },
    mounted() {
        this.queryFlJobDataInfo(this.id)
        this.token = getToken()
        this.imgUrl = sessionStorage.getItem("uploadUrl")+'/viewpic?path=';
        let that = this
        let arr = this.algorithm.filter(function(item) {
            if(item.selected) {
                
                that.$emit('choosesf',item.name,item.id, item.nameCn)
                return
            }
        })
        if(this.partInfo == 'guest') {
            // 联邦方
            this.showSf = true;
        } else {
            // 参与方
            this.showSf = false
        }
    },
    computed: {
        hasMultiType() {
            return (item)=>{
                let array = this.checkMulabel
                return array.includes(item)
            }
        }
    },
    methods: {
        // 单标签的数据处理
        dealWithData(arr,objArray) {
            let resultArray = []
            objArray.map(obj => {
                let newObj = {};
                arr.forEach(index => {
                    if (obj[index] !== undefined) {
                        newObj.jobId = this.id,
                        newObj.partyId =this.partyId,
                        newObj.selectedFeature = index;
                        newObj.selectedLabel = obj[index];
                        resultArray.push(newObj)

                    }
                });
            });
            return resultArray
        },
        // 单标签多分类增加接口
        async addSelectedFeature() {
            let params = this.dealWithData(this.checkMulti,this.multiList)
          
            await addSelectedFeature(params,getTenantId()).then(res=>{
                if(res.data.code == 200) {
                    this.checkMulabel = [],
                    this.checkMulti = []
                    this.$message.success(res.data.result)
                   
                } else {
                    this.$message.error(res.data.message)
                }
            })
            .catch(error=>{

            })
        },
        // 判断当前参数是数据还是字符串
        isStringOrArray(item) {
            if (Array.isArray(item)) {
                let array = []
                item.forEach((item,index)=>{
                    array.push(Object.keys(item)[0])
                   
                })
                return array
            } else {
                // 数据为字符串，需要转换成数据
                return item.split(',')
            }

        },
        // 绑定key
        getKey() {
            // 防止渲染错列错行
            return Math.random()
        },
        // 多标签切换重新获取数据
        handleCheckedMulalelChange() {
            this.$nextTick(() => {
                // 防止表头闪动
                this.$refs.multipleTable.doLayout()
            })
            
        },
        // 单标签多分类切换重新获取数据
        handleCheckedMultiChange(val) {
            this.filterArray = []
            this.filterArray = val.filter(item => item !== null && item !== undefined && item !== '')
            this.listOfMata(this.filterArray.toString())
        },
        // 页面遮罩层
        loadFullPage() {
            this.loadingFull = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
        },
        // 选择数据集列和特征值
        async selectedColumnAndFeature() {
            this.loadFullPage()
            let Url =  sessionStorage.getItem("uploadUrl")
            let data = {
                jobId: this.id,
                tableName: this.tableName,
                colums: this.columnAndFeature.colume,
                features: this.columnAndFeature.features,
                createParty: sessionStorage.getItem("nameEn")
            }
            await selectedColumnAndFeature(data,Url,getTenantId()).then(res=>{
                this.loadingFull.close()
                this.loading = false
                if(res.data.code == 200) {
                    let tableName = res.data.result.tableName
                    this.$message.success(res.data.message)
                    this.dataSetSuccess(tableName)
                    // this.$emit('dataSetSuccess',tableName)
                } else {
                    this.$message.error(res.data.message)
                }
            })
            .catch(err=>{
                this.loadingFull.close()
                this.loading = false
            })
        },
        // 数据集复用成功
        dataSetSuccess(tableName) {
            this.tableName = tableName
            this.chooseVisible = false
            this.listOfMata()
        },
        // 关闭数据集复用弹框
        closeDialog() {
            this.chooseVisible = false
        },
        // 数据集列和特征值展示
        async columnAndFeatureOfMeta() {
            let Url =  sessionStorage.getItem("uploadUrl")
            this.loadFullPage()
            let data = {
                tableName: this.tableName
            }
            await columnAndFeatureOfMeta(data,Url,getTenantId()).then(res=>{
                this.loadingFull.close()
                if(res.data.code == 200) {
                    this.features = res.data.result.features
                    if(res.data.result.colums.length) {
                        let array = []
                        this.colums = []
                        array = res.data.result.colums
                        array.forEach(ele => {
                            this.colums.push({
                                key: ele,
                                label: ele
                            })
                        });
                    }
                    this.chooseVisible = true
                }
            })
            .catch(err=>{
                this.loadingFull.close()
            })
        },
      
        // 心跳检查
        async healthz(fn) {
            this.loadFullPage()
            let Url =  sessionStorage.getItem("uploadUrl");
            await healthz(Url).then(res=>{
                this.loadingFull.close() 
                if(res.data.code == 200 ) {
                    // 心跳检测成功
                    fn()  
                 
                } else {
                    // 心跳检测失败
                    this.$message({
                        dangerouslyUseHTMLString: true,
                        type: 'error',
                        duration: 3 * 1000,
                        message: `网络安全证书缺失，<a class="ssl" style="color:#145358;"  type="primary">请下载对应证书</a>`
                        
                    })
                    // const ssl = document.getElementsByClassName("ssl");
                    // for (var i = 0; i < ssl.length; i++) {
                    //     ssl[i].addEventListener("click", () => {
                    //         this.$message.closeAll()
                    //         this.$DialogSSL()
                    //     });
                    // }
                }
            })
            .catch(error=>{
                this.loadingFull.close()   
            })
        },
        // 选择全量数据
        chooseAllDataClick() {
            this.newData = []
            this.createDataVisible = false
            this.$confirm('是否使用全量数据集作为训练数据？点击确定完成数据选择，选择部分数据请点击取消', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                }).then(() => {
                    //点击确定
                    this.newData = []
                    this.healthz(this.selectedMeta)
                    // this.selectedMeta()
                }).catch(() => {
                    //点击取消
                    this.createDataVisible = true
                    // this.$message.error('请勾选数据集')      
                });
        },
        // 选择本页数据
        chooseAllClick(rows) {
            
            if (rows) {
                // 全选
                if(this.selectAll) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row,true);
                    });
                    // this.selectAll = !this.selectAll
                } else {
                    this.$refs.multipleTable.clearSelection();
                    // this.selectAll = !this.selectAll
                }
                
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        // 放大图片
        bigImg(row) {
            let url = this.imgUrl + row +'&token=' + this.token
            this.srcList= []
            this.srcList.push(url)
            // console.log(row)
        },
        handleSizeChange(val) {
            this.pagination.limit = val
            this.healthz(this.listOfMata)
            // this.listOfMata()
        },
        // 更新数据集
        async selectedMeta() {
            this.loadFullPage()
            let Url =  sessionStorage.getItem("uploadUrl");
            let data = {
                jobId: this.id,
                tableName: this.tableName,
                ids: this.newData,
                createParty: sessionStorage.getItem("nameEn"),
                selectedColumns: this.checkMulabel,
                // selectedFeatures: this.checkMulti
            }
            
            await selectedMeta(data, Url, getTenantId()).then(res=>{
                this.loadingFull.close() 
                if(res.data.code == 200) {
                    this.loadingFull.close()
                    this.createDataVisible = false
                    
                    if(this.partInfo == 'guest') {
                        this.queryDataId(this.chooseItemId)
                        // 联邦方
                        if(this.evalType == 'multi'){
                            // 单标签多分类
                            this.addSelectedFeature()
                        }
                       
                    } else {
                        this.$emit('reastChoose',this.dataId,this.dataName,this.compareFlag)
                        if(this.evalType == 'multi'){
                            // 单标签多分类
                            this.addSelectedFeature()
                        }
                       
                    }
                    
                }
                
            })
            .catch(err=>{
                this.$message.error(err.message)
                this.loadingFull.close() 
            })
        },
        // 选择数据条数
        chooseDataSaveClick() {
            // 点击确认按钮，选中数据
            if(this.newData.length > 0) {
                // console.log(this.newData)
                this.healthz(this.selectedMeta)
                 // this.selectedMeta()
                
            } else {
                this.createDataVisible = false
                this.$confirm('未选择任何数据，系统将默认您使用全量数据集，确定使用吗?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                }).then(() => {
                    //点击确定
                    this.newData = []
                    this.healthz(this.selectedMeta)

                    // this.selectedMeta()
                }).catch(() => {
                    //点击取消
                    this.createDataVisible = true
                    // this.$message.error('请勾选数据集')      
                });
                
            }
            
            // this.createDataVisible = false
            // this.queryDataId(this.chooseItemId)
          
        },
        //获取选择的key
        getKeys(row) {
            return row.id
            // console.log(row)
        },
        userSelectionChange(arr) {
            this.newData = []
            arr.map((item)=> {
                this.newData.push(Object.assign({}, {id: item.id}))
            })
           
        },
        handleChangePage(current) {
            this.pagination.currentPage = current;
            //   this.healthz()
            // this.healthz(this.listOfMata)
            this.listOfMata()
        },
        handleChangePagePic(current) {
            this.paginationPic.currentPage = current;
            //   this.healthz(this.listOfMata)
            this.listOfMata(this.checkMulti.toString())
        },
        tableRowClassName({row, rowIndex}) {
            if (rowIndex%2==0) {
                return 'warning-row';
            } else  {
                return 'success-row';
            }
        },
        // 数据集展示
        async listOfMata(item) {
            // this.loadFullPage()
            let Url =  sessionStorage.getItem("uploadUrl");
            let params = {}
            if(this.dataContentType == 'vision') {
                params = {
                    pageNo: this.paginationPic.currentPage,
                    pageSize: this.paginationPic.limit,
                    tableName: this.tableName,
                    selectFeatures: item
                }
            } else {
                params = {
                    pageNo: this.pagination.currentPage,
                    pageSize: this.pagination.limit,
                    tableName: this.tableName
                }
            }
            await listOfMata(params,Url,getTenantId()).then(res=>{
                // this.loadingFull.close()
                if(res.data.code == 200 ) {
                   
                    if(this.dataContentType == "tabular") {
                        // this.showPicTableFlg = false;
                        this.labelData = []
                        this.pagination.total  = res.data.result.total
                        this.tableData = res.data.result.data
                        let headerObj = JSON.parse(JSON.stringify( this.tableData[0]))
                        let arr = Object.keys(headerObj)
                        arr.forEach((item)=>{
                            this.labelData.push({
                                label: item,
                                prop: headerObj[item]
                            })
                        })
                        this.createDataVisible = true 
                    } else if(this.dataContentType == "vision") {
                        // this.showPicTableFlg = true;
                        this.paginationPic.total  = res.data.result.total
                        this.picListData = res.data.result.data
                        this.mulableList = res.data.result.targetColums
                       
                        this.multiList = res.data.result.features
                        this.checkMulabel = this.deepCopy(res.data.result.targetColums)
                        this.checkMulti = this.isStringOrArray(this.deepCopy(res.data.result.returnFeatures)) 
                        this.createDataVisible = true 
                 
                    }
                    
                }
            })
            .catch(error=>{
                // this.loadingFull.close()
            })
        },
        toggle(arr) {
            arr.forEach(item=>{
                this.$refs.multipleTable.toggleRowSelection(item)
            })
        },
        // 确认数据选择
        saveDataClick () {
            //点击确定 要重新调用
            
            if(this.showSf) {
                // 发起方
                this.$emit('reastChoose',this.dataId,this.dataName)
                this.createVisible = false
            } else {
                if(this.contentType != 'vision') {
                    // 参与方
                    this.summaryCompare(this.params)
                } else {
                    this.createVisible = false
                    this.compareFlag = true
                    this.$emit('reastChoose',this.dataId,this.dataName,this.compareFlag)
                }
                
            }
        },
        // 关闭dig
        beforeCloseClick() {
            // this.loadingFull.close()
            this.dataId = ''
            this.createVisible = false;
            this.datatypeFlag = false
        },
        beforeCloseDataClick() {
            this.loadingFull.close()
            this.createDataVisible = false;
            this.checkMulabel = [],
            this.checkMulti = []
        },
        // 查询数据具体信息
        async queryDataId(id) {
            let params = {
                id: id 
            }
            await queryDataId(params).then(res=>{
                if(res.data.code == 200) {
                    this.dataName =  res.data.result.name
                    this.contentType = res.data.result.contentType
                    this.targetself = res.data.result.createParty
                    // nlp数据的情况
                    if(this.contentType == 'nlp') {
                        if(this.partInfo == 'guest') {
                            // 发起方
                            this.dataId = res.data.result.id
                            this.saveDataClick()
                        } else {
                            // 参与方
                            this.dataId = id 
                            this.params = {
                        
                                self:{
                                    createParty: this.targetself, 
                                    id: this.dataId
                                },
                                // 发起方
                                target: {
                                    createParty: this.createParty, 
                                    id: this.fqId 
                                }
                            }
                            this.summaryCompare(this.params)
                        }
                    } 
                    else if(this.contentType == 'graph') {
                        if(this.partInfo == 'guest') {
                            // 发起方
                            this.dataId = res.data.result.id
                            this.saveDataClick()
                        } else {
                            // 参与方
                            this.dataId = id 
                            this.params = {
                        
                                self:{
                                    createParty: this.targetself, 
                                    id: this.dataId
                                },
                                // 发起方
                                target: {
                                    createParty: this.createParty, 
                                    id: this.fqId 
                                }
                            }
                            this.summaryCompare(this.params)
                        }
                    }
                    else if(this.contentType == 'yolo') {
                        if(this.partInfo == 'guest') {
                            // 发起方
                            this.dataId = res.data.result.id
                            this.saveDataClick()
                        } else {
                            // 参与方
                            this.dataId = id 
                            this.params = {
                        
                                self:{
                                    createParty: this.targetself, 
                                    id: this.dataId
                                },
                                // 发起方
                                target: {
                                    createParty: this.createParty, 
                                    id: this.fqId 
                                }
                            }
                            this.summaryCompare(this.params)
                        }
                    }
                    // 表格数据的情况
                    else if(this.contentType == 'tabular') {
                        if(this.partInfo == 'guest') {
                            // 联邦方
                             // csv数据
                            this.dataId = id 
                            this.saveDataClick()
                        } else {
                            // 参与方
                            // this.showSf = false
                            this.slectDataItem(res.data.result,id)
                        }
                    }
                    // 图片数据的情况 
                    else if (this.contentType == 'vision') {
                        if(this.partInfo == 'guest') {
                            // 联邦方
                            // 图片数据
                            this.dataId = res.data.result.id
                            this.saveDataClick()
                        } else {
                            // 参与方
                            // this.showSf = false
                            // this.slectDataItem(res.data.result,id)
                            this.dataId = id 
                            this.params = {
                        
                                self:{
                                    createParty: this.targetself, 
                                    id: this.dataId
                                },
                                // 发起方
                                target: {
                                    createParty: this.createParty, 
                                    id: this.fqId 
                                }
                            }
                            this.summaryCompare(this.params)
                        }
                    }


                    // if(this.contentType != 'vision') {
                    //     if(this.partInfo == 'guest') {
                    //         // 联邦方
                    //          // csv数据
                    //         this.dataId = id 
                    //         this.saveDataClick()
                    //     } else {
                    //         // 参与方
                    //         // this.showSf = false
                    //         this.slectDataItem(res.data.result,id)
                    //     }
                       
                        
                        
                    // } else {
                    //     if(this.partInfo == 'guest') {
                    //         // 联邦方
                    //         // 图片数据
                    //         this.dataId = res.data.result.id
                    //         this.saveDataClick()
                    //     } else {
                    //         // 参与方
                    //         // this.showSf = false
                    //         // this.slectDataItem(res.data.result,id)
                    //         this.dataId = id 
                    //         this.params = {
                        
                    //                 self:{
                    //                     createParty: this.createParty, 
                    //                     id: this.fqId
                    //                 },
                    //                 // 发起方
                    //                 target: {
                    //                     createParty: this.targetself, 
                    //                     id: this.dataId
                    //                 }
                    //         }
                    //         this.summaryCompare(this.params)
                    //     }
                       
                    // }
                    
                }
            })
            .catch(err=>{
            })
        },
        chooseDataClick(item) {
            this.evalType = ''
            this.chooseItemId = item.id
            this.dataContentType = item.contentType
            this.tableName = item.tableName
            // 判断类型 当前数据类型
            if(item.contentType == "nlp") {
                // nlp 数据
                if(this.partInfo == 'guest') {
                    this.queryDataId(item.id)
                    // 联邦方
                } else {
                    // 参与方
                    this.queryDataId(item.id)
                }
                
            } else if(item.contentType == 'graph') {
                // graph 数据
                if(this.partInfo == 'guest') {
                    this.queryDataId(item.id)
                    // 联邦方
                } else {
                    // 参与方
                    this.queryDataId(item.id)
                }
            } 
            else if(item.contentType == 'yolo') {
                // yolo 数据
                if(this.partInfo == 'guest') {
                    this.queryDataId(item.id)
                    // 联邦方
                } else {
                    // 参与方
                    this.queryDataId(item.id)
                }
            }
            else {
                // 图片数据和表格数据格式
                this.pagination = {
                    offset: 0,
                    total: 0,
                    currentPage: 1,
                    limit: 10
                }
                this.paginationPic = {
                    offset: 0,
                    total: 0,
                    currentPage: 1,
                    limit: 3
                }
                this.newData  = []
                if(this.partInfo == 'guest') {
                    // 联邦方
                    if(this.dataContentType == "tabular") {
                        this.evalType = item.evalType
                        this.showPicTableFlg = false;
                        this.healthz(this.columnAndFeatureOfMeta)
                    } else {
                        this.showPicTableFlg = true;
                        this.evalType = item.evalType
                        this.healthz(this.listOfMata)
                    }
                } else {
                    this.evalType = item.evalType
                    // 参与方
                    this.queryDataId(item.id)
                }
            }
           
            
        },
        chooseSf(item,index) {
            if(this.algFlag) {
                return 
            }
            // console.log(item)
            // this.currentSfIndex = index
            // this.activesf = true
           
            this.$emit('choosesf',item.name,item.id,item.nameCn)
        },
        slectDataItem(obj,id) {
            this.tableData1 = []
            this.tableData2 = []
            this.tableData1 = obj.columns.split(",")
            this.tableData1.filter(item=>{
                this.tableData2.push(JSON.parse(obj.columnType)[item])
            })
            this.dataId = id 
            this.params = {
        
                self:{
                    createParty: this.targetself, 
                    id: this.dataId
                },
                // 发起方
                target: {
                    createParty: this.createParty, 
                    id: this.fqId 
                }
            }
           
            this.summaryCompare(this.params)
            // this.datatypeFlag = true
        },
         // 对比信息
        async summaryCompare(params) {
            await summaryCompare(params).then(res=>{
                if(res.data.success) {
                   this.descFlag = true
                   if(res.data.result == null)  {
                        // 数据对比通过
                        this.compareFlag = true
                        if(this.contentType == "nlp") {
                            // nlp的数据情况
                            this.$emit('reastChoose',this.dataId,this.dataName,this.compareFlag)
                        }
                        else if(this.contentType == "graph") {
                            // 图神经数据 
                            this.$emit('reastChoose',this.dataId,this.dataName,this.compareFlag)
                        } 
                        else if(this.contentType == 'yolo') {
                            // yolo 数据
                            this.$emit('reastChoose',this.dataId,this.dataName,this.compareFlag)
                        }
                         else {
                            if(this.dataContentType == "tabular") {
                                // csv 表格数据
                                this.showPicTableFlg = false;
                                // this.selectedColumnAndFeature()
                                this.healthz(this.selectedColumnAndFeature)
                            } else {
                                // 图片数据
                                this.showPicTableFlg = true;
                                this.healthz(this.listOfMata)
                            }
                        }
                        
                   } else {
                        // 数据对比没通过
                        this.compareFlag = false
                        this.createVisible = false;
                        this.titleText = JSON.stringify(res.data.result)
                        
                   } 
                   
                }
            })
            .catch(res=>{
                if(this.originContentType != 'vision') {
                    // 不是图片数据集
                    if(res.message=='请等待发起方上传数据!') {
                        return
                    } else {
                        this.createVisible = true;
                    }
                }  else {
                    return
                }
                // console.log(this.originContentType)
                // console.log(res)
                
            })
        },
        // 发起方数据格式
        async queryFlJobDataInfo(id) {
            let params = {
                id: id
            }
            await queryFlJobDataInfo(params).then(res=>{
                if(res.data.success) {
                    this.createParty = res.data.result.createParty
                    this.originContentType = res.data.result.contentType
                    this.path = res.data.result.path
                    this.fqId = res.data.result.id
                    this.tableData1 = []
                    this.tableData2 = []
                    this.tableData1 = res.data.result.columns.split(",")
                    this.tableData1.filter(item=>{
                        this.tableData2.push(JSON.parse(res.data.result.columnType)[item])
                    })
                }
            })
            .catch(res=>{
                
            })
        },
    }
}
</script>
<style lang="scss" scoped>
    .left {
        width: 232px;
        // height: 1052px;
        height: 100%;
        background: #FFFFFF;
        border-radius: 3px;
        .top{
            height: 60vh;
            overflow: auto;
            padding: 0 30px;
            ::v-deep .el-row{
                display:flex;
                flex-wrap: wrap;
            }
            .top-title{
                padding-top: 50px;
                font-weight: 700;
                font-style: 18px;
            }
            // .dataName{
            //     display: -webkit-box;
            //     -webkit-box-orient: vertical;
            //     -webkit-line-clamp: 4;
            //     overflow: hidden;
            // }
            .content-box{
                display: flex;
                flex-wrap: wrap;
               
            }
        }
        .htop{
            height: 100vh;
            overflow: auto;
            padding: 0 30px;
            ::v-deep .el-row{
                display:flex;
                flex-wrap: wrap;
            }
            .top-title{
                padding-top: 50px;
                font-weight: 700;
                font-style: 18px;
            }
            .content-box{
                display: flex;
                flex-wrap: wrap;
               
            }
        }
        .bottom{
           
            height: 60vh;
            overflow: auto;
            overflow-y: auto;
            padding: 0 20px;
            ::v-deep .el-row{
                display:flex;
                flex-wrap: wrap;
            }
            .content-sf-item{
                text-align: center;
                 .item {
                    // line-height: 80px;
                    // overflow: hidden;
                    // text-align: center;
                    width: 80px;
                    height: 80px;
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    background-size: 80px 80px;
                    cursor: pointer;
                
                
                }
                .sfImg{
                    width: 80px;
                    height: 80px;
                    cursor: pointer;
                }
                .dataName{
                    // padding: 0 10px;
                    // width: 80px;
                    span{
                        text-align: center;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        word-break:break-all;
                        overflow: hidden;
                    }
                    margin-bottom: 20px;
                }
            }
        }
    }
    .content-item{
        text-align: center;
          .item {
            // line-height: 80px;
            // overflow: hidden;
            // text-align: center;
            width: 80px;
            height: 80px;
            font-size: 12px;
            display: flex;
            align-items: center;
            // background-size: 80px 80px;
            cursor: pointer;
        
        
        }
        .dataImg{
            width: 80px;
            height: 80px;
            cursor: pointer;
        }
        .dataName{
            // padding: 0 10px;
            // width: 80px;
            // text-align: center;
            span{
                display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            word-break:break-all;
            overflow: hidden;
            }
            
            margin-bottom: 20px;
        }
    }
  
    .data-box {
        
        background-image: url('../../../assets/tool_bg_sensor_dataset.svg');
    }
    .active-data-box {
        background-image: url('../../../assets/tool_bg_image_active_dataset.svg');
    }
    .suanfa-box {
        background-image: url('../../../assets/tool_bg_model.svg');
    }
    .active-suanfa {
        background-image: url('../../../assets/tool_btn_active_model.svg');
    }
    .right{
        margin-left: 20px;
        width: 100%;
    }
    .myTable {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 0 auto;
    width: 1400px;
    text-align: center;
    .theader{
        background-color: #b2daec;
    }
    tbody {
        display: block;
        height: 100px;
        // width: 1400px;

        
        tr {
            display: table;
            width: 100%;
            table-layout: fixed;
            td{
                width: 100px;
                border: 1px solid #cad9ea;
                color: #666;
                height: 40px;
            }
        }
    }
}
.table-box::-webkit-scrollbar {
    width: 3px;
    height: 5px;
}
.table-box::-webkit-scrollbar-track {
    width: 3px;
    height: 5px;
    background-color: rgba(102, 103, 104, 0.5);
}
.table-box::-webkit-scrollbar-track-piece{
    width: 3px;
    height: 3px;
}
.table-box::-webkit-scrollbar-thumb {
    background-color: rgba(102, 103, 104, 0.5);
}
.table-box{
    overflow: auto;
}
.save{
       
    margin-left: 20px;
}
::v-deep .el-card__body{
    padding: 0;
}
::v-deep .el-select{
    width: 100%;
}
.digbox{
    height: 400px;
    overflow: auto;
}
::v-deep .el-dialog__body {
    overflow: auto;
}
.photo-box{
    height: 300px;
    padding: 30px;
    display: flex;
    overflow: auto;
    // background-color: #ccc;
    border: 1px solid #ccc;
    border-radius: 10px;
    .photo-left {
        flex: 1;
        .radiu-box{ 
            position: relative; 
            width: 100px;
            height: 92px;
            cursor: pointer;
            background: linear-gradient(to right, #ededf6,#fff);
            box-shadow: 0px 3px 15px 9px rgba(226,226,226,0.29);
            margin-bottom: 40px;
            .content-box{ 
                position: absolute;
                top: 20px;
                width: 100%;
                height: 100%;
                text-align: center;
                .content-title{
                        margin-top: 20px;
                    // height: 40px;
                    font-size: 12px;
                    // font-weight: 700;
                }
                .content-footer{
                    
                    margin-top: 50px;
                    ::v-deep .el-tag {
                        margin-right: 10px;
                    }
                }
            }
        }

        .radiu-box:before{
            position: absolute; 
            top:-10px; 
            content: "";
            width: 100px; 
            height: 20px; 
            border-radius:50%; 
            background: #fff;
            box-shadow: 0px 3px 15px 9px rgba(226,226,226,0.29);
        }

        .radiu-box:after{
            position: absolute; 
            bottom:-10px; 
            content: ""; 
            width: 100px; 
            height: 20px; 
            border-radius:50%; 
            background: linear-gradient(to right, #ededf6,#fff);
            // box-shadow: 0px 3px 15px 9px rgba(226,226,226,0.29);
        }
    }
    ::v-deep .el-divider--vertical{
        height: 100%;
    }
    .photo-right {
        flex: 1;
    }

}
  ::v-deep .el-table .warning-row {
    background: oldlace;
  }

  ::v-deep .el-table .success-row {
    background: #f0f9eb;
  }
  .block{
    margin-top:20px;
    text-align: center;
    display: flex;
    justify-content:center;
    align-items: center;
    ::v-deep .el-pagination {
        flex: 1;
    }
  }
  .dialogTitle{
    font-weight: 700;
    // font-size: 20px;
    margin-right: 30px;
  }
  ::v-deep .el-link--inner{
    text-decoration:underline;
  }
  .pic-table{
    ::v-deep .el-table{
        
    }
    // ::v-deep .el-table__body:hover{
    //     background: rgba(255,255,255,1);
    // }
    ::v-deep .el-table .cell{
        text-align: center;
    }
    ::v-deep .el-table__row{
        // display: flex;
    }
    ::v-deep .el-table__header-wrapper{
        // display:none;
    }
    // ::v-deep .el-table_1_column_2  {
    //     display:none;
    // }
    // ::v-deep .el-table_1_column_3 {
    //     display:none;
    // }
    // ::v-deep .el-table_1_column_5 {
    //     display:none;
    // }
    // ::v-deep .el-table_1_column_6{
    //     display:none;
    // }
  }
  
</style>
<style>
.el-image-viewer__wrapper{
    /* height: 80%; */
  }
 .el-image-viewer__canvas{
    height: 80%;
    margin-top:5%;
  }
</style>
<template>
    <div class="success-box">
        <div class="title">
            成功加入联邦方<br>"{{name}}"
        </div>
       
        <div class="loginbtn">
            <div class="center">
                <el-form label-position="left" :model="ruleForm" :rules="rules" ref="ruleForm">
                    <el-form-item label="科室" prop="orgCode" >
                        <el-select style="width:85%" v-model="ruleForm.orgCode" placeholder="请选择科室">
                            <el-option v-for="(item) in listArray" 
                                :key="item.key"
                                :label="item.departName" 
                                :value="item.orgCode">
                                </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            
            <div class="one">
                <el-button  @click="gotohome" type="primary">进入主页</el-button>
            </div>
            <div class='two'>
                <el-button  @click="nextbind">继续绑定</el-button>
            </div>
            
        </div>
    </div>
</template>
<script>
import {getFlUserPermission ,queryTreeListByPartyId, queryPartyInfo , showFlUserRole,updateOrg} from '@/api/index.js'
import { getAuth, setAuth, setTenantId , setPartyInfo, setIndex , setUserRole} from '@/utils/auth.js'
import { mapState } from 'vuex';
export default {
    data() {
        return {
            name: '',
            partyId: '',
            ruleForm: {
                orgCode: '',
            },
            listArray:[],
            rules: {
                orgCode: [
                    { required: true, message: '请选择科室', trigger: 'change' }
                ],
            },
            index: 0,
        }
    },
    computed: {
        ...mapState({
            id: state=> state.user.userInfo.id
        })
    },
    created () {
        this.name = this.$route.query.name
        this.partyId = this.$route.query.id
        this.queryTreeListByPartyId()
    },
    methods: {
        gotohome() {
            this.index = 1
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    // 校验通过
                    this.updateOrg()
                    
                } else {
                    return false;
                }
            });
            
        },
        nextbind() {
             this.index = 2
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    // 校验通过
                    this.updateOrg()
                } else {
                    return false;
                }
            });
            
        },
        // 获取用户所属联邦方
        async queryPartyInfo() {
            await queryPartyInfo().then(res=>{
                if(res.data.success) {
                    // 用户所属联邦方
                    setPartyInfo(JSON.stringify(res.data.result))
                    if(res.data.result.length==1){
                        let id = res.data.result[0].id
                        let name = res.data.result[0].name
                        sessionStorage.setItem('showname',name)
                        setTenantId(id)
                        this.showFlUserRole(id)
                        this.getFlUserPermission(id)
                    } else if(res.data.result.length>1) {
                        setIndex('index')
                        this.$router.push({
                            name: 'Index'
                        })
                    }
                    
                }
            })
            .catch(res=>{
                
            })
        },
        // 获取用户权限
        async getFlUserPermission(id) {
            await getFlUserPermission(id).then(res=>{
                // 权限
                setAuth(JSON.stringify(res.data.result))
                this.Auth =JSON.parse(getAuth()) 
                setIndex('')
                this.$router.push({
                    name: 'newhome'
                })
            })
            .catch(res=>{
                
            }) 
        },
        // 获取用户角色及状态
        async showFlUserRole(id) {
            await showFlUserRole(id).then(res=>{
                
                if(res.data.success) {
                    // 用户角色
                    setUserRole(JSON.stringify(res.data.result))
                }
            })
            .catch(res=>{
                
            })
        },
        // 查询组织结构
        async queryTreeListByPartyId() {
            let data= {
                partyId: this.partyId
            }
            await queryTreeListByPartyId(data).then(res=>{
                if(res.data.code == 0) {
                    this.listArray = res.data.result[0].children
                }
                console.log(res)
            })
            .catch(res=>{

            })
        },
        // 保存科室
        async updateOrg() {
            let data = {
                id: this.id,
                orgCode: this.ruleForm.orgCode 
            }
            await updateOrg(data).then(res=>{
                if(res.data.code == 200) {
                    if(this.index == 1) {
                        this.queryPartyInfo()
                    } else if(this.index == 2) {
                        this.$router.push({
                            name: 'Bind',
                            query: {
                                second: 1
                            }
                        })
                    }
                }
            })
            .catch(res=>{

            })
        }
    }
}
</script>
<style lang="scss" scoped>
.success-box{
    height: 100%;
    background-color: #fff;
    padding: 0 100px;
    width: 400px;
    .title{
        color: #484848;
        font-size: 40px;
        font-weight: 700;
        padding-top: 140px;
        text-align: center
    }
    
    .loginbtn{
        margin-top: 155px;
        cursor: pointer;
        width: 100%;
        .center{
            margin-bottom: 40px;
        }
        .one{
            margin-bottom: 40px;
            ::v-deep .el-button {
                width: 100%;
                height: 50px;
                background-color: #145358;
                font-size: 18px;
            }
        }
        .two{
            ::v-deep .el-button {
                width: 100%;
                height: 50px;
                font-size: 18px;
            }
        }
        
    }

}
</style>

<template>
    <el-dialog
        title="规则匹配"
        v-if="multiRuleDialogVisible"
        :visible.sync="multiRuleDialogVisible"
        :before-close='beforeCloseClick'
        width="50%"
        class="rule-box"
        center>
        <el-form :model="formData" ref="form">
            <el-table 
                :header-cell-style="headerStyle" 
                class="rule-table" 
                border 
                :span-method="objectSpanMethod" 
                :data="formData.tableData">
                <el-table-column
                    label="序号"    
                    type="index"
                    width="50">
                </el-table-column>
                <el-table-column property="partyName" label="联邦方" width="200"></el-table-column>
                <el-table-column property="selectedLabel" label="selectedLabel" width="200"></el-table-column>
                <el-table-column property="rule" label="rule(必须是连续的数字)">
                    <template inline-template slot-scope="scope">
                        <el-form-item :prop="`tableData.${scope.$index}.rule`" :rules="rules" >
                            <el-input
                                v-model.number="scope.row.rule"
                                type="number"
                                placeholder="请输入数字" ></el-input>
                        </el-form-item>
                    </template>
                </el-table-column>
            </el-table>
        </el-form>
       
        <span slot="footer" class="dialog-footer">
            <el-button @click="beforeCloseClick">取 消</el-button>
            <el-button :loading="loading"  type="primary" @click="confirmClick">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default{
    props: {
        visible: Boolean,
        ruleTableData: Array,
        loading: Boolean
    },
    data() {
        return {
            headerStyle: {
                backgroundColor:"#145358",
                color:" #fff",
                border:"none",
                height: '40px',
                fontSize: '14px'
            },
            spanArr:[],
            formData: {
                tableData: []
            },
            tableData: [
            ],
            ruleObj: {

            },
            rules:[{ required: true, message: '请输入数字'}],
           
        }   
    },
    computed: {
        multiRuleDialogVisible: {
            get() {
                return this.visible
            }
        }
    },
    watch: {
        ruleTableData(newValue,oldValue) {
           this.tableData= newValue
           this.formData.tableData = newValue
           this.flitterData()
        }
    },
    created() {
        
    },
    methods: {
        // 确定按钮点击
        confirmClick() {
            let ruleData =  this.formData.tableData.map(item => ({
                id: item.id,
                rule: item.rule
            }) )
            let rules = this.formData.tableData.map(item=>item.rule)
            this.$refs.form.validate(async(valid) =>{
                if(valid){
                    this.$emit('updateFeature',ruleData)
                }
            })
        },
        // 关闭dig
        beforeCloseClick() {
            this.$emit('cancelClick')
            this.$refs["form"].resetFields()
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
           
            if (columnIndex === 1) {
                  if(this.spanArr[rowIndex]){
                      return {
                          rowspan:this.spanArr[rowIndex],
                          colspan:1
                      }
                  }else{
                      return {
                          rowspan: 0,
                          colspan: 0
                      }
                  }
              }
        },
        flitterData () { 
            let contactDot = 0;
            let spanArr = [];
            this.formData.tableData.forEach((item, index) => {
                if (index === 0) { 
                    spanArr.push(1)
                } else {
                    //name对应的名称,value.dataList 对应table绑定的数据源
                    if (item.partyName === this.formData.tableData[index - 1].partyName) {
                        
                        spanArr[contactDot] += 1;
                        spanArr.push(0)
                    } else {
                        contactDot = index
                        spanArr.push(1)
                    }
                }
            });
            this.spanArr = spanArr;
        }
      
    },
}
</script>
<style scoped lang="scss">
.rule-box{
        
    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
    }
    ::v-deep input[type='number'] {
        -moz-appearance: textfield !important;
    }

   
}
.rule-table{
    height: 500px;
    overflow-y: auto;
}
</style>